<template>
    <div class="container">
        <div class="row">
            <div class="col pt-24">
                <div class="mb-16 bg-neutral-95 p-16 r-16">
                    <div class="bg-surface p-16 r-8">
                        
                        <div class="text-headline-m bold mb-16">Simulator de credite</div>
                        <div class="d-flex gap-8 align-end width-full mb-16">
                            <div>
                                <button
                                    @mousedown="plusMinusSuma('minus')"
                                    @touchstart="plusMinusSuma('minus')"
                                    class="button mb-28 mr-0 height-32 width-32 r-full p-0"
                                    @mouseup="clearplusMinusSuma()"
                                    @touchend="clearplusMinusSuma()"
                                >-</button>
                            </div>
                            <div class="spacer">
                                <div class="most-results">
                                    <div class="d-flex height-96 align-end">
                                        <svg id="grafic" width="1000" height="100" viewBox="0 0 1000 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <linearGradient id="gradient" gradientTransform="rotate(90)">
                                                <stop class="main-stop" offset="0%" />
                                                <stop class="alt-stop" offset="100%" />
                                            </linearGradient>
                                            <path :d="`M 1000 100 H 0 V 100 ${svgCoords()} V 100 Z`" fill="url(#gradient)"/>
                                        </svg>
                                    </div>
                                </div>
                                <vue3-slider
                                    v-model="suma"
                                    class="mb-0"
                                    :max="sumaMaxima"
                                    :min="sumaMinima"
                                    :step="sumaMinima"
                                    :height="8"
                                    :tooltip="true"
                                    :tooltipText="'%v Lei'"
                                />
                                <div class="text-title-s bold mt-8">Suma: <span class="color-primary">{{suma}}</span> Lei</div>
                            </div>
                            <div>
                                <button
                                    @mousedown="plusMinusSuma('plus')"
                                    @touchstart="plusMinusSuma('plus')"
                                    class="button mb-28 mr-0 height-32 width-32  r-full p-0"
                                    @mouseup="clearplusMinusSuma()"
                                    @touchend="clearplusMinusSuma()"
                                >+</button>
                            </div>
                        </div>
                        

                        <div class="d-flex gap-8 align-end width-full">
                            <div>
                                <button
                                    @mousedown="plusMinusPerioada('minus')"
                                    @touchstart="plusMinusPerioada('minus')"
                                    class="button mb-28 mr-0 height-32 width-32 r-full p-0"
                                    @mouseup="clearplusMinusPerioada()"
                                    @touchend="clearplusMinusPerioada()"
                                >-</button>
                            </div>
                            <div class="spacer">
                                
                                <vue3-slider
                                    v-model="perioada"
                                    class="mb-0"
                                    :max="perioadaMaxima"
                                    :min="perioadaMinima"
                                    :step="stepPerioada"
                                    :height="8"
                                    :tooltip="true"
                                    :formatTooltip="getPerioadaSlider"
                                    @drag-end="updateprev()"
                                    @dragging="updatePerioada()"
                                />
                                <div class="text-title-s bold mt-8">Perioada: {{getPerioada(perioada)}}</div>
                            </div>
                            <div>
                                <button
                                    @mousedown="plusMinusPerioada('plus')"
                                    @touchstart="plusMinusPerioada('plus')"
                                    class="button mb-28 mr-0 height-32 width-32 r-full p-0"
                                    @mouseup="clearplusMinusPerioada()"
                                    @touchend="clearplusMinusPerioada()"
                                >+</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="text-title-l color-neutral-99">Avem {{(companii.length != 1 ? (companii.length + ' rezultate ') : ' un rezultat ' )}} pentru tine:</div>
            </div>
        </div>

        <div class="row">
            <div v-for="(companie, index) in companii" :key="companie.nume" class="col xs-2 md-3">
                <div :class="{'mb-16': true, 'text-center': true}">

                    <div :id="`dialog-${index}`" :class="{'dialog': true, 'opened': companie.opened}">
                        <div class="dialog-inner width-320">
                            <div class="image contain height-80 mb-16 r-12 overflow-hidden bg-white p-4">
                                <img :src="companie.logo" :alt="companie.alt">
                            </div>
                            <div class="mb-8 text-title-m bold">{{companie.nume}}</div>
                            <p class="text-body-m">{{companie.descriere}}</p>
                            <button class="button button-m outlined ripple dialog-action" @click="dialogAction(companie, false)">
                                <span>Inapoi</span>
                            </button>
                            <a @click="dataLayerPush(companie.nume)" :href="companie.url" target="_blank" class="button button-m">
                                <span>Aplica acum</span>
                            </a>
                        </div>
                    </div>

                    <div :id="`overlay-for-dialog-${index}`" :class="{'overlay': true, 'for-dialog': true, 'opened': companie.opened}" @click="dialogAction(companie, false)"></div>

                    <div class="bg-neutral-95 r-16">
                        <div class="p-8 p-md-16">
                            <div class="image contain height-80 mb-16 r-12 overflow-hidden bg-white p-4">
                                <img :src="companie.logo" :alt="companie.alt">
                            </div>
                            <div class="mb-8 text-title-m bold">{{companie.nume}}</div>
                            <ul class="mb-0">
                                <li class="mb-8">
                                    <div class="text-title-m color-secondary bold">Suma:</div>
                                    <div class="text-label-m color-tertiary bold min-height-16">{{companie.sumaMinima}} - {{companie.sumaMaxima}} Lei</div>
                                </li>

                                <li class="mb-8">
                                    <div class="text-title-m color-secondary bold">Perioada:</div>
                                    <div class="text-label-m color-tertiary bold min-height-16">{{getPerioada(companie.perioadaMinima)}} - {{getPerioada(companie.perioadaMaxima)}}</div>
                                </li>

                                <li class="mb-8">
                                    <div class="text-title-m color-secondary bold">DAE:</div>
                                    <div class="text-label-m color-tertiary bold min-height-16">{{companie.dae}}</div>
                                </li>
                            </ul>
                            <a @click="dialogAction(companie, true)" class="flip-toggle d-flex width-full text-body-s height-24 text-center justify-center mb-16">Mai multe detalii</a>
                            <a @click="dataLayerPush(companie.nume)" :href="companie.url" target="_blank" class="d-flex width-full button button-m">Aplica acum</a>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

    // const dataLayer = []

    // window.filterApp = {
    //     "companii": [{
    //         "nume": "Provident",
    //         "logo": "https://media.moneezy.com/2257/conversions/provident_136_111.png",
    //         "alt": "Alt Text",
    //         "sumaMinima": 500,
    //         "sumaMaxima": 20000,
    //         "perioadaMinima": 365,
    //         "perioadaMaxima": 1143,
    //         "daeMaxima": "96.22%",
    //         "url": "#",
    //         "dae": "de la 240%",
    //         "opened": false,
    //         "descriere": "Dacă ești la primul împrumut, poți obține un credit cu dobânda 0%. Pentru rambursarea împrumutului, ai la dispoziție cel puțin patru modalități de plată: card, cont, depunere sau transfer la bancă și stațiile SeflPay. Din contul de client, poți vedea oricând informații referitoare la scadență, rate, dobândă etc. Pentru transparența procesului de aprobare, timpul scurt de primire a unui răspuns și condiții de eligibilitate lejere, dar ținând cont și de programul de lucru fragmentat, considerăm că acest produs de creditare are calificativul de 88%."
    //     }, {
    //         "nume": "HoraCredit",
    //         "logo": "https://media.moneezy.com/2208/conversions/horacredit_136_111.png",
    //         "alt": "Alt Text",
    //         "sumaMinima": 100,
    //         "sumaMaxima": 20000,
    //         "perioadaMinima": 30,
    //         "perioadaMaxima": 365,
    //         "url": "#",
    //         "dae": "",
    //         "opened": false,
    //         "descriere": "Dacă ești la primul împrumut, poți obține un credit cu dobânda 0%. Pentru rambursarea împrumutului, ai la dispoziție cel puțin patru modalități de plată: card, cont, depunere sau transfer la bancă și stațiile SeflPay. Din contul de client, poți vedea oricând informații referitoare la scadență, rate, dobândă etc. Pentru transparența procesului de aprobare, timpul scurt de primire a unui răspuns și condiții de eligibilitate lejere, dar ținând cont și de programul de lucru fragmentat, considerăm că acest produs de creditare are calificativul de 88%."
    //     }, {
    //         "nume": "ViaConto",
    //         "logo": "https://media.moneezy.com/2216/conversions/viaconto_136_111.png",
    //         "alt": "Alt Text",
    //         "sumaMinima": 2000,
    //         "sumaMaxima": 12000,
    //         "perioadaMinima": 30,
    //         "perioadaMaxima": 1143,
    //         "url": "#",
    //         "dae": "de la 240%",
    //         "opened": false,
    //         "descriere": "Dacă ești la primul împrumut, poți obține un credit cu dobânda 0%. Pentru rambursarea împrumutului, ai la dispoziție cel puțin patru modalități de plată: card, cont, depunere sau transfer la bancă și stațiile SeflPay. Din contul de client, poți vedea oricând informații referitoare la scadență, rate, dobândă etc. Pentru transparența procesului de aprobare, timpul scurt de primire a unui răspuns și condiții de eligibilitate lejere, dar ținând cont și de programul de lucru fragmentat, considerăm că acest produs de creditare are calificativul de 88%."
    //     }, {
    //         "nume": "Ferratum",
    //         "logo": "https://media.moneezy.com/2283/conversions/ferratum_136_111.png",
    //         "alt": "Alt Text",
    //         "sumaMinima": 1100,
    //         "sumaMaxima": 5000,
    //         "perioadaMinima": 30,
    //         "perioadaMaxima": 120,
    //         "url": "#",
    //         "dae": "de la 240%",
    //         "opened": false,
    //         "descriere": "Dacă ești la primul împrumut, poți obține un credit cu dobânda 0%. Pentru rambursarea împrumutului, ai la dispoziție cel puțin patru modalități de plată: card, cont, depunere sau transfer la bancă și stațiile SeflPay. Din contul de client, poți vedea oricând informații referitoare la scadență, rate, dobândă etc. Pentru transparența procesului de aprobare, timpul scurt de primire a unui răspuns și condiții de eligibilitate lejere, dar ținând cont și de programul de lucru fragmentat, considerăm că acest produs de creditare are calificativul de 88%."
    //     }, {
    //         "nume": "Axi",
    //         "logo": "https://media.moneezy.com/2183/conversions/axi-card_136_111.png",
    //         "alt": "Alt Text",
    //         "sumaMinima": 350,
    //         "sumaMaxima": 19000,
    //         "perioadaMinima": 30,
    //         "perioadaMaxima": 365,
    //         "url": "#",
    //         "dae": "de la 240%",
    //         "opened": false,
    //         "descriere": "Dacă ești la primul împrumut, poți obține un credit cu dobânda 0%. Pentru rambursarea împrumutului, ai la dispoziție cel puțin patru modalități de plată: card, cont, depunere sau transfer la bancă și stațiile SeflPay. Din contul de client, poți vedea oricând informații referitoare la scadență, rate, dobândă etc. Pentru transparența procesului de aprobare, timpul scurt de primire a unui răspuns și condiții de eligibilitate lejere, dar ținând cont și de programul de lucru fragmentat, considerăm că acest produs de creditare are calificativul de 88%."
    //     }]
    // };

import slider from "vue3-slider"
// import './assets/css/azura-framework.css';

export default {
    name: 'App',
    components: {
        "vue3-slider": slider
    },
    data() {
        return {
            suma: 3000,
            stepPerioada: null,
            series: [60, 30, 80, 20, 12, 20, 20],
            hold: false,
            chartOptions: {
                chart: {
                    type: 'area',
                    height: '40px',
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 500
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 600
                        }
                    }
                },
                stroke: {
                    curve: 'smooth',
                    width: 0,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ['var(--primary-90)'],
                        inverseColors: false,
                        opacityFrom: .3,
                        opacityTo: .5,
                        stops: [0, 100],
                        colorStops: []
                    },
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    enabled: false
                },
                colors: ['var(--primary-40)']
            },
            perioada: null,
            sumaMinima: null,
            sumaMaxima: null,
            perioadaMinima: null,
            perioadaMaxima: null,
            companiiData: [],
            timer: null,
            timeout: null
        }
    },
    created() {
        this.companiiData = window.filterApp.companii;

        this.sumaMinima     = this.getRange('min', 'sumaMinima')
        this.sumaMaxima     = this.getRange('max', 'sumaMaxima')
        this.perioadaMinima = this.getRange('min', 'perioadaMinima')
        this.perioadaMaxima = this.getRange('max', 'perioadaMaxima')

        this.perioada = 360
        this.timer    = 300

        this.setStepPerioada()
        this.waves()
    },
    computed: {
        companii () {
            return this.companiiData.filter(companie => this.isSuma(companie, this.suma) && this.isPerioada(companie))
        },
    },
    methods: {
        svgCoords () {
            let array = []
            let index = this.series.length

            let width      = 1000 / this.series.length;
            let widthTotal = width

            while (index > 0) {
                let height = this.series[index-1] * 100 / this.companiiData.length
                array.push(`L ${widthTotal} ${100 - height}`)
                index--
                widthTotal = widthTotal + width
            }

            return array.join(' ')
        },
        dialogAction (event, action) {
            this.companii.map(companie => companie.opened = false);
            event.opened = action
        },
        changeTimer () {
            this.timer = this.timer < 50 ?  50 : this.timer * 0.9
        },
        maxDivisible (action) {
            const perioada = action == 'minus' ? this.perioadaMinima : this.perioadaMaxima
            return Math.floor(perioada / this.stepPerioada) * this.stepPerioada
        },
        perioadaInterval (action) {
            if (this.hold && this.perioada != this.maxDivisible(action)) {
                this.setPerioada(action)
                this.changeTimer(action)
                this.timeout = setTimeout(() => {
                    this.waves()
                    this.perioadaInterval(action)
                }, this.timer)
            } else {
                this.clearplusMinusPerioada()
            }
        },
        sumaInterval (action) {
            const suma = action == 'minus' ? this.sumaMinima : this.sumaMaxima
            if (this.hold && this.suma != suma) {
                this.setSuma(action)
                this.changeTimer(action)
                this.timeout = setTimeout(() => {
                    this.sumaInterval(action)
                }, this.timer)
            } else {
                this.clearplusMinusSuma()
            }
        },
        plusMinusPerioada (action) {
            this.timer = 300
            this.hold  = true
            this.perioadaInterval(action)
        },
        plusMinusSuma (action) {
            this.timer = 300
            this.hold  = true
            this.sumaInterval(action)
        },
        setPerioada (action) {
            this.setStepPerioada(action)
            const newPerioada = action == 'minus' ? this.perioada - this.stepPerioada : this.perioada + this.stepPerioada
            this.perioada = newPerioada
        },
        setSuma (action) {
            this.suma = action == 'minus' ? this.suma - this.sumaMinima : this.suma + this.sumaMinima
        },
        setStepPerioada (action) {
            this.stepPerioada = this.perioada >= (action == 'minus' ? 120 : 90) ? 30 : 1
        },
        clearplusMinusPerioada () {
            this.hold = false;
            clearTimeout(this.timeout)
            this.updateprev()
        },
        clearplusMinusSuma () {
            this.hold = false;
            clearTimeout(this.timeout)
        },
        getPerioada (perioada) {
            return perioada > 89 ? Math.floor(perioada / 30) + ' Luni' : Math.floor(perioada) + (Math.floor(perioada) === 1 ? ' Zi' : ' Zile')
        },
        getPerioadaSlider () {
            return this.perioada > 89 ? Math.floor(this.perioada / 30) + ' Luni' : Math.floor(this.perioada) + (Math.floor(this.perioada) === 1 ? ' Zi' : ' Zile')
        },
        getRange (range, param) {
            return Math[range].apply(Math, this.companiiData.map(companie => { return companie[param] }))
        },
        waves () {
            var index        = this.sumaMaxima / 100;
            var sumaProgress = this.sumaMaxima;
            var array        = [];

            while (index > 0) {
                array.push(this.companiiData.filter(companie => this.isSuma(companie, sumaProgress) && this.isPerioada(companie)).length)
                index--;
                sumaProgress -= 100;
            }
            this.series = array
        },
        isSuma (companie, suma) {
            return companie.sumaMinima <= suma && companie.sumaMaxima >= suma
        },
        isPerioada (companie) {
            return companie.perioadaMinima <= this.perioada && companie.perioadaMaxima >= this.perioada
        },
        updateprev () {
            this.waves()
        },
        updatePerioada () {
            this.waves()
            this.setStepPerioada()
        },
        dataLayerPush (numeCompanie) {
            dataLayer.push({'event': 'clickAplica', 'companie': numeCompanie, 'suma': this.suma, 'perioada': this.getPerioada(this.perioada)})
        }
    }
}
</script>

<style>
#app {
    margin-left: auto;
    margin-right: auto;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
    float: none;
}

.vue3-slider {
    background-color: var(--neutral-80);
    border-radius: 8px;
}

.vue3-slider .handle {
    background-color: white !important;
    transform: scale(1) !important;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3), 0 2px 6px 2px rgba(0,0,0,.15) !important;
    z-index: 99999;
}

.vue3-slider .tooltip {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3), 0 2px 6px 2px rgba(0,0,0,.15) !important;
}

.vue3-slider:hover .handle {
    transform: scale(1.35) !important;
}

.vue3-slider .track-filled {
    background-color: var(--primary-40) !important;
    border-radius: 8px;
}

#filter-app a.button:visited,
#filter-app a.button:hover {
    color: var(--neutral-99);
}

.height-280 {
    height: 304px;
}

.flip-toggle {
    cursor: pointer;
}

.height-264 {
    height: 264px !important;
}

.gap-1 {
    column-gap: 1px;
}

.bg-gradient-graph {
    background-image: linear-gradient( 0deg, var(--primary-40) 0%, var(--primary-90) 100%);
}

#grafic {
    width: 100%;
    height: auto;
}

#grafic path {
    transition: 0.2s;
}

.main-stop {
  stop-color: var(--primary-40);
}
.alt-stop {
  stop-color: var(--primary-99);
}

.mb-28 {
    margin-bottom: 15px !important;
}

/*--------------------------------------------------------------
# DIALOGS
--------------------------------------------------------------*/

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%) scale(.9);
    z-index: 12000;
    transition: all .175s ease-in-out;
}

.dialog.opened {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}

.dialog-inner {
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.3), 0 8px 12px 6px rgba(0,0,0,.15) !important;
    border-radius: 28px;
    color: var(--neutral-10);
    background-color: var(--neutral-99);
    padding: 24px;
    z-index: 1;
    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
}

.dialog-inner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: var(--primary-40);
    opacity: .14;
    border-radius: 28px;
}

/*--------------------------------------------------------------
# OVERLAY
--------------------------------------------------------------*/

.overlay {
    position: fixed;
    top: -100px;
    left: -100px;
    right: -100px;
    bottom: -100px;
    background-color: var(--neutral-10);
    opacity: 0;
    visibility: hidden;
    z-index: 11000;
    animation: opacityOverlayOpen .175s 1 cubic-bezier(0.165, 0.840, 0.440, 1.000);
    animation-fill-mode: forwards;
    transition: opacity .175s cubic-bezier(0.165, 0.840, 0.440, 1.000);
}

.overlay.opened {
    opacity: .4;
    visibility: visible;
}

.overlay.out {
    animation: opacityOverlayout .175s 1 cubic-bezier(0.165, 0.840, 0.440, 1.000);
    animation-fill-mode: forwards;
    pointer-events: none;
}

</style>